<template>
  <v-layout row wrap>
    <!-- Start login fields -->
    <v-flex xs12 sm12 md5>
      <!-- Start fields -->
      <v-card
        :img="mdUp ? null : sBackgroundSource"
        tile
        flat
        :style="mdUp ? 'fieldsWrapper' : 'fieldsWrapperMobile'"
      >
        <v-container
          style="max-height: 100vh"
          :class="mdUp ? 'LoginContainer' : 'mobileLoginContainer'"
          fill-height
        >
          <v-layout
            :class="
              smUp
                ? 'LoginFieldsColumnWrapper'
                : 'LoginFieldsColumnWrapperMobile'
            "
            column
            fill-height
            justify-center
            :align-center="!mdUp"
          >
            <div>
              <!-- Start logo -->
              <v-layout row fill-height justify-center>
                <v-card
                  tile
                  flat
                  contain
                  style="width: 150px; height: 200px; margin-bottom: 40px"
                  :img="sLogoSource"
                ></v-card>
              </v-layout>
              <!-- End logo -->
              <!-- Start title -->
              <v-layout row fill-height justify-center>
                <p class="LoginTitleText fontRaleway">Inicio de sesión</p>
              </v-layout>
              <!-- End title -->
              <!-- Start email field -->
              <v-layout
                style="margin-top: 85px; margin-bottom: -15px"
                row
                fill-height
                :justify-center="!mdUp"
              >
              </v-layout>
              <p
                style="
                  font-size: 14px;
                  font-weight: 600;
                  font-family: 'Poppins', sans-serif;
                "
              >
                Correo electrónico
              </p>
              <v-layout row fill-height :justify-center="!mdUp">
                <v-text-field
                  outlined
                  clearable
                  solo
                  dense
                  background-color="#FAFAFA"
                  class="loginTextField"
                  v-on:keyup.enter="submitAuthentication()"
                  v-model="credentials.sEmail"
                  placeholder="Correo electrónico"
                >
                </v-text-field>
              </v-layout>
              <!-- End email field -->
              <!-- Start password field -->
              <v-layout
                style="margin-top: 15px"
                row
                fill-height
                :justify-center="!mdUp"
              >
              </v-layout>
              <p
                style="
                  font-size: 14px;
                  font-weight: 600;
                  font-family: 'Poppins', sans-serif;
                "
              >
                Contraseña
              </p>
              <v-layout row fill-height :justify-center="!mdUp">
                <v-text-field
                  outlined
                  clearable
                  solo
                  dense
                  background-color="#FAFAFA"
                  class="loginTextField"
                  v-on:keyup.enter="submitAuthentication()"
                  v-model="credentials.sPassword"
                  :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (bShowPassword = !bShowPassword)"
                  :type="bShowPassword ? 'text' : 'password'"
                  placeholder="••••••••••"
                >
                </v-text-field>
              </v-layout>
              <!-- End password field -->
              <!-- Start button for submition -->
              <v-layout row fill-height justify-center>
                <v-btn
                  @click="submitAuthentication()"
                  class="purl-button-login"
                  elevation="5"
                >
                  Iniciar sesión
                </v-btn>
                <!-- <v-btn
                  @click="submitAuthentication()"
                  block
                  depressed
                  style="
                    margin-top: 50px;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 10px;
                  "
                  large
                  color="#FFCE00"
                >
                  <p class="fieldLabelButton fontRaleway">
                    {{ texts.sLoginButton }}
                  </p>
                </v-btn> -->
              </v-layout>
              <!-- End button for submittion -->
            </div>
          </v-layout>
        </v-container>
      </v-card>
      <!-- End field -->
    </v-flex>
    <!-- End login fields -->
    <!-- Start login image -->
    <v-flex v-if="mdUp" sm12 md7>
      <v-card tile flat class="maximumWidth maximumHeight">
        <v-layout row justify-center align-center fill-height wrap>
          <v-card
            flat
            tile
            class="maximumWidth maximumHeight"
            :img="sPhoneImage"
          >
          </v-card>
        </v-layout>
      </v-card>
    </v-flex>
    <!-- End login image -->
  </v-layout>
</template>
<script>
export default {
  methods: {
    // send credentials to service
    submitAuthentication() {
      this.bLoading = true;
      this.$store
        .dispatch("login", this.credentials)
        .then((resp) => {
          this.bLoading = false;
          this.clearForm();
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    clearForm() {
      this.credentials = {
        sEmail: "",
        sPassword: "",
      };
    },
  },
  data: () => ({
    // store user inputs for service
    credentials: {
      sEmail: "",
      sPassword: "",
    },
    // boolean for password show
    bShowPassword: false,
    // image source for login
    sBackgroundSource: "/static/Control3_Background.png",
    sLogoSource: "/static/Control3_Logo.png",
    sPhoneImage: "/static/Control3_Background.png",
    sBaseballBlur: "/static/baseball_blur.png",
    sSoccerBallBlur: "/static/soccer_ball_blur.png",
  }),
  props: {
    texts: Object,
  },
  computed: {
    // medium breakpoints
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    // small breakpoint
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
<style>
.purl-button-login {
  height: 60px !important;
  border-radius: 10px;
  width: 55%;
  margin-top: 15px;
  font-weight: 600;
  background-color: #ffce00 !important;
  font-size: 14px !important;
}

.loginTextField .v-input__control .v-input__slot fieldset {
  height: 55px;
  border-radius: 10px;
  background-color: #fafafa;
  border: 1px solid #f3f3f3;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  height: 49px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
    0px 0px 0px 0px rgb(0 0 0 / 0%);
}

.loginTextField input::placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #707070 !important;
  font-size: 12px;
  padding: 10px;
}
.loginTextField input::-moz-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #707070 !important;
  font-size: 12px;
  padding: 10px;
}
</style>
<style scoped>
.baseBallSytle {
  max-width: 200px;
  position: fixed;
  right: -80px;
  top: 40px;
}
.soccerBallStyle {
  max-width: 200px;
  position: fixed;
  bottom: -60px;
  right: 50%;
}
.LoginFieldsColumnWrapper {
  margin-top: 0%;
  max-width: 80%;
  padding-left: 20%;
}
.LoginFieldsColumnWrapperMobile {
  margin-top: 0%;
  width: 100%;
}
.fieldLabelButton {
  font-family: "Raleway", sans-serif !important;

  font-size: 12px;
  font-weight: 600;
  margin: 10px;
  text-align: left;
  color: black;
}
.fieldLabel {
  text-align: left;
  color: black;
}
.fieldLabelMobile {
  margin-bottom: -5px;
  font-size: 12px;
  text-align: center;
  color: black;
}
.mobileLoginContainer {
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
}
.LoginContainer {
  min-height: 100vh;
}
.LoginTitleText {
  margin-top: 20px;
  margin-bottom: -40px;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  color: black;
  font-family: "Poppins", sans-serif !important;
}
.fieldsWrapper {
  width: 100%;
  min-height: 120vh;
  height: 120vh;
  background-color: white;
}
.fieldsWrapperMobile {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>